<template>
  <div>
    <div id="screen">
      <!-- 标题 -->
      <header>
        <h1 v-if="titleInput" @click="titleInput = false">
          {{ Title ? Title : "暂无标题" }}
        </h1>
        <Input
          v-else
          v-model="Title"
          style="width: 300px"
          @on-blur="titleInput = true"
          @keyup.enter.native="titleInput = true"
        />
      </header>
      <!-- 图片 -->
      <section>
        <div>
          <div
            id="before"
            :style="{ width: imgWidth + 'px', height: imgHeight + 'px' }"
          >
            <div v-if="BC">
              <img
                v-if="toggleBeforeAngle"
                class="Compass"
                :src="compass"
                :style="{
                  transform: 'rotateZ(' + compassDeg + 'deg)',
                }"
                @click="toggleBeforeAngle = false"
                title="点击输入旋转角度"
              />
              <Input
                v-else
                id="beforeCompassAngle"
                type="number"
                v-model="compassDeg"
                @on-blur="toggleBeforeAngle = true"
                @keyup.enter.native="toggleBeforeAngle = true"
              />
            </div>
            <img id="beforeImg" :src="beforeImgSrc" />
            <!-- <img id="beforeImg" onmousewheel="return bbimg(this)" /> -->
            <span class="footnotes" style="color: #2e58ed"
              >湖南总队通信比武灾前卫星影像图</span
            >
            <div
              class="scale"
              id="BSdiv"
              v-if="BSdiv"
              style="text-align: center"
            >
              <p v-if="toggleBS" @click="toggleBS = false">
                <span id="beforeScale">{{ BS }}</span
                >m
              </p>
              <Input
                v-else
                size="small"
                type="number"
                v-model="BS"
                id="BSinput"
                @on-blur="toggleBS = true"
                @keyup.enter.native="toggleBS = true"
              />
              <div
                class="plotting_scale"
                :style="{ width: proportion + 'px' }"
              ></div>
            </div>
          </div>
        </div>

        <div>
          <div
            id="after"
            :style="{ width: imgWidth + 'px', height: imgHeight + 'px' }"
          >
            <img class="Compass" :src="compass" />
            <img id="afterImg" ref="afterImg" :src="img" />
            <canvas id="canvas" width="900" height="700"></canvas>
            <span class="footnotes" style="color: #ff0015"
              >湖南总队通信比武灾后正射影像图</span
            >
            <div class="scale">
              <p
                v-if="toggleAS"
                @click="toggleAS = false"
                style="text-align: center"
              >
                <span id="afterScale">{{ AS }}</span
                >m
              </p>
              <Input
                v-else
                size="small"
                v-model="AS"
                type="number"
                id="ASinput"
                @on-blur="toggleAS = true"
                @keyup.enter.native="toggleAS = true"
              />
              <div
                class="plotting_scale"
                :style="{ width: proportion + 'px' }"
              ></div>
            </div>
          </div>
        </div>
      </section>
      <!-- 画布控件，截图时隐藏 -->
      <aside>
        <div style="font-size: 18px; margin-bottom: 20px">
          <span>经度：</span>
          <span @click="toggleLng = false" v-if="toggleLng" id="lng"
            >{{ lng ? lng : "请输入经度" }}E</span
          >
          <Input
            v-if="!toggleLng"
            v-model="lng"
            id="lngInput"
            style="width: 100px"
            @on-blur="toggleLng = true"
            @keyup.enter.native="toggleLng = true"
          />
          <span>，纬度：</span>
          <span @click="toggleLat = false" v-if="toggleLat" id="lat"
            >{{ lat ? lat : "请输入纬度" }}N</span
          >
          <Input
            v-if="!toggleLat"
            id="latInput"
            v-model="lat"
            style="width: 100px"
            @on-blur="toggleLat = true"
            @keyup.enter.native="toggleLat = true"
          />
        </div>
      </aside>
      <!-- 信息 -->
      <footer>
        <div>
          <span>制图单位：</span>
          <span @click="toggleUnit = false" v-if="toggleUnit">{{
            Unit ? Unit : "点击输入制图单位"
          }}</span>
          <Input
            v-else
            v-model="Unit"
            style="width: 300px"
            @on-blur="toggleUnit = true"
            @keyup.enter.native="toggleUnit = true"
          />
        </div>
        <div>
          <span>制图时间：</span>
          <span
            title="点击编辑制图时间"
            v-if="toggleTime"
            @click="toggleTime = false"
            id="time"
            >{{ DrawingTime ? DrawingTime : "暂无制图时间" }}</span
          >
          <Input
            v-else
            v-model="DrawingTime"
            id="timeInput"
            style="width: 300px"
            @on-blur="toggleTime = true"
            @keyup.enter.native="toggleTime = true"
          />
        </div>
      </footer>
    </div>

    <!-- 保存 -->
    <div class="dlc">
      <div>
        <input
          ref="selectBefore"
          type="file"
          style="display: none"
          @change="select"
        />
        <Button @click="$refs.selectBefore.click()" style="margin-bottom: 7px"
          >点击选择图片</Button
        >
      </div>
      <div>
        <Button @click="clearAllMark" style="margin-right: 20px"
          >清除全部标注</Button
        >
        <Button title="保存为图片" type="info" @click="saveCanvas">
          保存为图片
        </Button>
        <a href="" download="下载图.png" id="download"></a>
      </div>
    </div>
  </div>
</template>

<script>
import html2canvas from "html2canvas";
import compass from "@/assets/img/Icon/compass.png";

export default {
  data() {
    return {
      Title: "湖南省长沙市训保支队灾前灾后二维正射影像对比图",
      imgHeight: 500,
      imgWidth: 700,
      titleInput: true,
      compass,
      lng: 0,
      lat: 0,
      img: null,
      Distance: 0,
      beforeImgSrc: "",
      proportion: 10,
      BC: false,
      BSdiv: false,
      compassDeg: 0,
      toggleBeforeAngle: true,
      toggleBS: true,
      toggleAS: true,
      BS: 10,
      AS: 10,
      Unit: "湖南总队长沙消防救援支队监制",
      toggleUnit: true,
      screenNow: 1,
      context: null,
      theCanvas: null,
      toggleTime: true,
      DrawingTime: new Date().toLocaleString(),
      toggleLng: true,
      toggleLat: true,
      timer: "",
    };
  },
  mounted() {
    this.lng = this.$route.params.lng.toFixed(4);
    this.lat = this.$route.params.lat.toFixed(4);
    this.img = this.$route.params.img;
    this.Distance = this.$route.params.Distance;
    let that = this;
    setTimeout(() => {
      that.proportion = Math.round(
        that.$refs.afterImg.width / Math.round(this.Distance / 10)
      );
      that.imgWidth = that.$refs.afterImg.width;
      that.imgHeight = that.$refs.afterImg.height;
    }, 50);
    this.draw();
    this.timer = setInterval(() => {
      that.DrawingTime = new Date().toLocaleString(); // 修改数据date
    }, 1000);
  },
  methods: {
    // toBase64() {
    //   let img = new Image();
    //   console.log(this.img);
    //   let canvas2 = document.createElement("canvas");
    //   let ctx = canvas2.getContext("2d");
    //   img.crossOrigin = "Anonymous";
    //   img.src = this.img;
    //   img.onload = function () {
    //     console.log("1");

    //     canvas2.height = img.height;
    //     canvas2.width = img.width;
    //     ctx.drawImage(img, 0, 0);

    //     let dataURL = canvas2.toDataURL("image/png");
    //     this.img = dataURL;
    //     console.log(this.img);
    //     canvas2 = null;
    //     //重新给img赋值成功后，执行截图方法
    //     // saveCanvas();
    //   };
    // },
    // 选择本地图片
    select() {
      let that = this;
      let file = this.$refs.selectBefore.files[0];
      that.beforeImgSrc = file.files;
      let reader = new FileReader();
      if (file) {
        reader.readAsDataURL(file);
      }
      reader.onloadend = function () {
        //   console.log(reader.result);
        that.BC = true;
        that.BSdiv = true;
        that.beforeImgSrc = reader.result;
      };
    },
    /**
     * 保存网页
     * localhost运行会由于跨域报错
     */
    saveCanvas() {
      this.screenNow = 0;
      html2canvas(document.querySelector("#screen"), {
        allowTaint: true,
        scale: 2,
        tainttest: true, //检测每张图片都已经加载完成
      }).then(function (canvas) {
        // document.body.appendChild(canvas);
        let imgUrl = canvas.toDataURL("image/png");
        $("#download").attr("href", imgUrl);
        document.getElementById("download").click();
      });
      this.screenNow = 1;
    },

    // 标注

    draw() {
      let that = this;
      that.theCanvas = document.getElementById("canvas");
      that.context = canvas.getContext("2d");
      that.context.strokeStyle = "red";
      //检测兼容canvas
      if (!that.theCanvas || !that.theCanvas.getContext) {
        return false;
      } else {
        let isAllowDrawLine = false;
        const windowToCanvas = (canvas, x, y) => {
          let rect = canvas.getBoundingClientRect();
          //x和y参数分别传入的是鼠标距离窗口的坐标，然后减去canvas距离窗口左边和顶部的距离。
          return {
            x: x - rect.left * (canvas.width / rect.width),
            y: y - rect.top * (canvas.height / rect.height),
          };
        };
        that.theCanvas.onmousedown = function (e) {
          isAllowDrawLine = true;
          //获得鼠标按下的点相对canvas的坐标。
          let ele = windowToCanvas(that.theCanvas, e.clientX, e.clientY);
          let { x, y } = ele;
          that.context.moveTo(x, y); // 起点
          that.theCanvas.onmousemove = (e) => {
            if (isAllowDrawLine) {
              // && drawing
              //移动时获取新的坐标位置，用lineTo记录当前的坐标，然后stroke绘制上一个点到当前点的路径
              let ele = windowToCanvas(that.theCanvas, e.clientX, e.clientY);
              let { x, y } = ele;
              that.context.lineTo(x, y);
              that.context.stroke();
            }
          };
        };
        that.theCanvas.onmouseup = function () {
          isAllowDrawLine = false;
        };
      }
      // this.toBase64();
    },
    // 清除标注
    clearAllMark() {
      if (this.context) {
        this.context.clearRect(
          0,
          0,
          this.theCanvas.width,
          this.theCanvas.height
        );
        this.context.beginPath();
      }
    },
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer); // 在Vue实例销毁前，清除我们的定时器
    }
  },
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
}
#screen {
  width: 1580px;
  margin: 0 auto 20px;
}
header {
  padding-top: 20px;
  margin-bottom: 40px;
  text-align: center;
}
header > h1 {
  font-weight: bolder;
  font-size: 40px;
}
section {
  display: flex;
  justify-content: space-evenly;
  font-size: 16px;
}
footer {
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  font-weight: bold;
  padding: 0 40px;
}
#beforeImg,
#afterImg {
  max-width: 750px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#before,
#after {
  position: relative;
  overflow: hidden;
  border: 1px solid #ccc;
  background-color: #fafafa;
}
#canvas {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  cursor: pointer;
}
aside {
  margin: 10px 70px 30px;
  text-align: right;
}
.footnotes {
  position: absolute;
  left: 15px;
  bottom: 10px;
  font-weight: bolder;
  font-size: 20px;
  text-shadow: -2px -2px 0px #fff, 2px 2px 0 #fff;
  z-index: 101;
}
.Compass {
  position: absolute;
  top: 10px;
  right: 20px;
  z-index: 101;
}
#beforeCompassAngle,
#afterCompassAngle {
  position: absolute;
  top: 28px;
  right: 20px;
  z-index: 101;
  width: 70px;
  height: 20px;
}
.dlc {
  /* margin-top: 20px; */
  padding: 20px 200px 0;
  /* text-align: right; */
  display: flex;
  justify-content: space-between;
}

.scale {
  position: absolute;
  right: 10px;
  bottom: 20px;
  z-index: 6666;
}
#BSinput,
#ASinput {
  width: 70px;
  height: 20px;
  margin: 0;
}
.scale p {
  font-size: 14px;
  color: #fff;
  text-shadow: #000 0 1px 0, #000 1px 0 0, #000 0 -1px 0, #000 -1px 0 0;
}
.plotting_scale {
  height: 2px;
  position: relative;
  background-color: #000;
  top: 5px;
}
.plotting_scale::before,
.plotting_scale::after {
  content: "";
  position: absolute;
  background-color: #000;
  width: 2px;
  height: 8px;
  top: -3px;
}
.plotting_scale::before {
  left: -2px;
}
.plotting_scale::after {
  right: -2px;
}
</style>
